import React from "react"
import { graphql, Link } from "gatsby"
import { Container, Grid, Themed } from "theme-ui"
import Layout from "../components/interactive-space/layout"
import getSlug from "speakingurl"
import BgLeft from "../images/bg-left.png"
import BgRight from "../images/bg-right.png"

const EspacioInteractivo = ({ data }) => {

  const dataFragments = data.dataJson

  const contentMenu = [
    [ getSlug(dataFragments.fragment_6), dataFragments.fragment_6 ],
    [ getSlug(dataFragments.fragment_7), dataFragments.fragment_7 ],
    [ getSlug(dataFragments.fragment_8), dataFragments.fragment_8 ]
  ]

  return(
    <Layout
      bgImageRight={BgRight}
      bgImageLeft={BgLeft}
    >
      <Container
        as={`main`}
        sx={{
          width: `100%`,
          paddingTop: 3,
          paddingRight: [0, 0, 0, 4, 4, 4],
          paddingLeft: [0, 0, 0, 4, 4, 4],
          h1: {
            color: `gray.6`,
            fontSize: 6,
          },
          "p, ul, li": {
            color: `gray.6`,
          }
        }}
      >
        <Themed.h1>{dataFragments.fragment_1}</Themed.h1>
        <Themed.p dangerouslySetInnerHTML={{ __html: dataFragments.fragment_2}} />
        <Themed.p dangerouslySetInnerHTML={{ __html: dataFragments.fragment_3}} />
        <Themed.p dangerouslySetInnerHTML={{ __html: dataFragments.fragment_4}} />
        <Themed.p dangerouslySetInnerHTML={{ __html: dataFragments.fragment_5}} />
        <Grid
          as={`ul`}
          columns={2}
          gap={[2, 3]}
          sx={{
            listStyle: `none`,
            margin: 0,
            padding: 0,
            li: {
              display: `block`,
              marginTop: 1,
              marginBottom: 1,
            },
            a: {
              backgroundColor: `tertiary`,
              border: 0,
              color: `gray.6`,
              display: `block`,
              fontSize: [2, 2, 2, 2, 3, 3],
              height: `100%`,
              textAlign: `center`,
              padding: [2, 2, 2, 3, 3, 4],
              width: `100%`,
              "&:hover, &:active, &:focus": {
                border: 0,
                color: `gray.6`,
              },
            },
            ".access-to-section-0": {
              gridArea: `section-0`,
            },
            ".access-to-section-1": {
              gridArea: `section-1`,
            },
            ".access-to-section-2": {
              gridArea: `section-2`,
            },
            gridTemplateAreas: `"section-0 section-0" "section-1 section-2"`,
          }}
        >
          {contentMenu.map((item, index) => (
          <Themed.li key={index} className={`access-to-section-${index}`}>
            <Themed.a
              as={Link}
              to={`/${item[0]}`}
              rel="bookmark"
            >
              {index === 0
                ? <span>{item[1]}</span>
                : <span>{dataFragments.fragment_9} {item[1]}</span>
              }
            </Themed.a>
          </Themed.li>
          ))}
        </Grid>
      </Container>
    </Layout>
  )

}

export default EspacioInteractivo

export const query = graphql`
  query EspacioInteractivo {
    dataJson(slug: {eq: "page-espacio-interactivo"}) {
      fragment_1
      fragment_2
      fragment_3
      fragment_4
      fragment_5
      fragment_6
      fragment_7
      fragment_8
      fragment_9
    }
  }
`